<template>
  <ion-page>
    <WhiteHeader
      :back-button="true"
      :route-back="routeBack"
      title="Rapport mission"
    />

    <ion-content class="ion-padding">
      <ion-row class="ion-justify-content-center">
        <ion-button
          v-for="(value, displayable) in missionDays"
          :key="value"
          :fill="currentDay === value ? 'solid' : 'outline'"
          size="small"
          color="dark"
          @click="onDateClick(value)"
        >
          {{ displayable }}
        </ion-button>
      </ion-row>

      <ion-grid v-for="(question, key) in questions" :key="'question' + key">
        <h2>{{ question.title }}</h2>

        <ion-grid v-for="field in question.fields" :key="field.uniqid">
          <h5>
            {{ field.name }}

            {{ field.kind === questionKinds.NUMBER ? '(Litres)' : '' }}
          </h5>

          <!-- TEXTAREA -->
          <ion-row
            v-if="field.kind === questionKinds.TEXTAREA && reports[currentDay]"
          >
            <ion-textarea
              v-if="field.property"
              placeholder="Saisir..."
              :auto-grow="true"
              v-model="reports[currentDay][field.property]"
            ></ion-textarea>

            <ion-textarea
              v-else-if="reports[currentDay].custom_questions"
              placeholder="Saisir..."
              :auto-grow="true"
              v-model="reports[currentDay].custom_questions[field.uniqid]"
            ></ion-textarea>
          </ion-row>

          <!-- BOOLEAN -->
          <ion-row
            v-if="field.kind === questionKinds.BOOLEAN && reports[currentDay]"
          >
            <ion-segment
              :value="getValueForField(field)"
              @ionChange="onBooleanChange($event.target.value, field)"
              mode="ios"
            >
              <ion-segment-button
                v-for="(value, displayable) in booleanValues"
                :key="value + displayable"
                class="width-segment-form-driver"
                :value="value"
              >
                <ion-label class="fs-12">{{ displayable }}</ion-label>
              </ion-segment-button>
            </ion-segment>
          </ion-row>

          <!-- DATERANGE -->
          <ion-grid v-if="field.kind === questionKinds.DATERANGE">
            <ion-row class="ion-align-items-center">
              <ion-label>Heure de début&nbsp;:</ion-label>
              <ion-button
                :id="'btn-start' + field.property"
                size="small"
                class="ion-margin-start"
              >
                {{ getDateTimeButtonLabel(field.property, 0) }}
              </ion-button>
              <ion-modal
                :trigger="'btn-start' + field.property"
                :keep-contents-mounted="true"
                class="custom-modal"
              >
                <ion-datetime
                  :show-default-buttons="true"
                  done-text="Valider"
                  cancel-text="Annuler"
                  :id="'start-datetime' + field.property"
                  v-model="reports[currentDay][field.property][0]"
                ></ion-datetime>
              </ion-modal>
            </ion-row>

            <ion-row class="ion-align-items-center ion-margin-top">
              <ion-label>Heure de fin&nbsp;:</ion-label>
              <ion-button
                :id="'btn-end' + field.property"
                size="small"
                class="ion-margin-start"
              >
                {{ getDateTimeButtonLabel(field.property, 1) }}
              </ion-button>
              <ion-modal
                :trigger="'btn-end' + field.property"
                :keep-contents-mounted="true"
                class="custom-modal"
              >
                <ion-datetime
                  :show-default-buttons="true"
                  done-text="Valider"
                  cancel-text="Annuler"
                  :id="'end-datetime' + field.property"
                  v-model="reports[currentDay][field.property][1]"
                ></ion-datetime>
              </ion-modal>
            </ion-row>
          </ion-grid>

          <!-- TIMESTAMP_NUMBER -->
          <ion-grid v-if="field.kind === questionKinds.TIMESTAMP_NUMBER">
            <ion-input
              type="number"
              step="0.01"
              v-model="reports[currentDay][field.property]"
            ></ion-input>

            <ion-row class="ion-align-items-center">
              <ion-label>Horodatage&nbsp;:</ion-label>

              <ion-button
                :id="'btn-day' + field.property"
                size="small"
                class="ion-margin-start"
              >
                {{ getDateTimeButtonLabel(field.property + '_timestamp') }}
              </ion-button>

              <ion-modal
                :trigger="'btn-day' + field.property"
                :keep-contents-mounted="true"
                class="custom-modal"
              >
                <ion-datetime
                  :show-default-buttons="true"
                  done-text="Valider"
                  cancel-text="Annuler"
                  :id="'day-datetime' + field.property"
                  v-model="reports[currentDay][field.property + '_timestamp']"
                ></ion-datetime>
              </ion-modal>
            </ion-row>
          </ion-grid>

          <!-- NUMBER -->
          <ion-row v-if="field.kind === questionKinds.NUMBER">
            <ion-input
              type="number"
              step="0.01"
              v-model="reports[currentDay][field.property]"
            ></ion-input>
          </ion-row>

          <!-- FILE -->
          <attachments
            v-if="field.kind === questionKinds.FILE"
            v-model:attachments="reports[currentDay][field.property]"
            v-model:waiting-files="waitingFiles"
            v-model:attachments-to-delete="attachmentsToDelete"
            v-model:attachments-to-update="attachmentsToUpdate"
            type-vue="create"
            :files-too-big="false"
            :kind="field.property"
          />
        </ion-grid>
      </ion-grid>
    </ion-content>

    <div class="ion-padding bottom">
      <ion-button color="tertiary" expand="block" @click="save()">
        Enregistrer
      </ion-button>
      <ion-button expand="block" color="dark" class="mt-10" @click="save(true)">
        Enregistrer puis remplir le BL
      </ion-button>
    </div>
  </ion-page>
</template>

<script>
import WhiteHeader from '@/components/driver/header/WhiteHeader';
import {
  IonButton,
  IonContent,
  IonDatetime,
  IonGrid,
  IonInput,
  IonLabel,
  IonModal,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonTextarea,
  toastController,
} from '@ionic/vue';
import { getParams } from '@/services/users';
import questionKinds from '@/services/constants/questionKinds';
import axios from 'axios';
import dayjs from 'dayjs';
import Attachments from '@/components/driver/rentalCoupon/components/Attachments.vue';

export default {
  components: {
    IonButton,
    IonContent,
    IonGrid,
    IonRow,
    IonTextarea,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    IonDatetime,
    IonModal,
    IonInput,
    WhiteHeader,
    Attachments,
  },

  setup() {
    return {
      questionKinds,
    };
  },

  data() {
    return {
      routeBack: null,
      questions: {},
      reports: {},
      missionDays: [],
      currentDay: null,

      // files
      waitingFiles: [],
      attachmentsToDelete: [],
      attachmentsToUpdate: {},

      // static
      booleanValues: {
        'Oui': true,
        'Non': false,
        'Pas concerné': null,
      },
    };
  },

  mounted() {
    this.routeBack = this.$route.fullPath.replace('/report', '');
    this.refreshQuestions();
  },

  methods: {
    goToGoodPage(createCoupon = false) {
      if (!createCoupon) {
        this.$router.replace(this.routeBack);
        return;
      }

      const currentPath = this.$route.fullPath;
      let link = '/';

      if (currentPath.includes('chantier')) {
        link += 'chantier/' + this.$route.params.id;
        link += '/' + this.$route.params.idRide;
      } else {
        const kind = currentPath.includes('livraison')
          ? 'livraison'
          : 'transfert';

        link +=
          kind +
          '/' +
          this.$route.params.id +
          '/' +
          this.$route.params.idRide +
          '/' +
          this.$route.params.idItem;
      }

      link += '/bonLocation/create';
      this.$router.push(link);
    },

    async refreshQuestions() {
      const dataToSend = this.getMissionRelatedData();

      const response = await axios.post(
        'driver/mission-report/list',
        dataToSend,
        await getParams(),
      );

      this.questions = response.data.questions;
      this.reports = response.data.reports;
      this.missionDays = response.data.mission_days;

      this.onDateClick(Object.values(this.missionDays)[0]);
    },

    onDateClick(newDate) {
      this.currentDay = newDate;

      // if we already have the report for this day, don't need to init
      if (this.reports[this.currentDay]) return;

      this.reports[this.currentDay] = {};
      this.reports[this.currentDay].custom_questions = {};

      // get all fields in one big array
      const flatten = Object.values(this.questions).flatMap(
        question => question.fields,
      );

      // init all answers to null
      flatten.forEach(field => {
        const empty = this.getEmptyForField(field);

        if (!field.property) {
          this.reports[this.currentDay].custom_questions[field.uniqid] = empty;
          return;
        }

        this.reports[this.currentDay][field.property] = empty;
      });
    },

    getEmptyForField(field) {
      if (field.kind === questionKinds.DATERANGE) return [null, null];
      if (field.kind === questionKinds.FILE) return [];

      return null;
    },

    getMissionRelatedData() {
      const { params, fullPath } = this.$route;
      const isReservation = fullPath.includes('chantier');

      return {
        related_id: isReservation ? params.id : params.idItem,
        related_type: isReservation ? 'App\\Reservation' : 'App\\TransportItem',
      };
    },

    getWaitingFilesFormData() {
      const formData = new FormData();

      // add all waiting files to our formData
      Object.values(this.waitingFiles).forEach(waitingFile => {
        let key = `${waitingFile.kind}_${waitingFile.randomId}_files`;
        formData.append(
          `${key}[]`,
          waitingFile,
          waitingFile.newName || waitingFile.name,
        );
        formData.append('keys[]', key);
      });

      return formData;
    },

    async save(createCoupon = false) {
      const formData = this.getWaitingFilesFormData();
      const dataToSend = this.getMissionRelatedData();
      dataToSend.reports = [];

      // dataToSend.reports = this.reports;
      const flatten = Object.values(this.questions).flatMap(
        question => question.fields,
      );

      Object.entries(this.reports).forEach(([day, value]) => {
        const line = { day };

        flatten.forEach(field => {
          // since daterange are stored as string in database we need to format them
          if (field.kind === this.questionKinds.DATERANGE) {
            if (!value[field.property].filter(v => !!v).length) return;

            const formatted = value[field.property].map(v =>
              v ? dayjs(v).format('YYYY-MM-DD HH:mm') : '',
            );

            line[field.property] = `[${formatted.join(',')}]`;
          } else if (field.property) {
            line[field.property] = value[field.property] || null;

            // if we have a timestamp number, we need to add the timestamp
            if (field.kind === questionKinds.TIMESTAMP_NUMBER) {
              line[field.property + '_timestamp'] =
                value[field.property + '_timestamp'] || null;
            }

            return;
          }

          line.custom_questions = line.custom_questions || {};
          line.custom_questions[field.uniqid] =
            value.custom_questions[field.uniqid];
        });

        dataToSend.reports.push(line);
      });

      dataToSend.attachmentsToUpdate = this.attachmentsToUpdate;
      dataToSend.attachmentsToDelete = this.attachmentsToDelete;
      formData.append('report_data', JSON.stringify(dataToSend));

      axios
        .post('/driver/mission-report', formData, await getParams())
        .then(() => {
          this.goToGoodPage(createCoupon);
          this.sendToast('Le rapport de mission a bien été enregistré');
        })
        .catch(() => {
          this.sendToast(
            "Une erreur est survenue lors de l'enregistrement du rapport de mission",
            'danger',
          );
        });
    },

    async sendToast(message, color = 'success') {
      const toast = await toastController.create({
        message,
        duration: 3000,
        color,
        position: 'top',
      });
      await toast.present();
    },

    /**
     * Get value for field
     *
     * if not custom question, we get the value from the reports
     * else, we get the value from the custom_questions
     */
    getValueForField(field) {
      if (field.property) return this.reports[this.currentDay][field.property];

      return this.reports[this.currentDay].custom_questions[field.uniqid];
    },

    /**
     * BOOLEAN is a possible multiple type
     */
    onBooleanChange(newVal, field) {
      if (field.property) {
        this.reports[this.currentDay][field.property] = newVal;
        return;
      }

      this.reports[this.currentDay].custom_questions[field.uniqid] = newVal;
    },

    getDateTimeButtonLabel(property, key = null) {
      const reportForDay = this.reports[this.currentDay];
      const value =
        key === null ? reportForDay[property] : reportForDay[property][key];

      if (!value) return 'Définir';

      return dayjs(value).format('DD/MM/YYYY HH:mm');
    },
  },
};
</script>

<style scoped lang="scss">
.bottom {
  position: sticky;
  // do not remove this version because we need it for safari
  position: -webkit-sticky;
  width: 100%;
  text-align: center;
  margin-left: auto;
}

ion-datetime-button::part(native),
.no-background::part(native) {
  background: #edeef0;
}

.custom-modal {
  --width: auto;
  --height: auto;
  --border-radius: 12px;
}

.custom-modal .modal-wrapper {
  width: auto;
  height: auto;
}

.custom-modal ion-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}

ion-textarea,
ion-input {
  border: 1px solid black;
  border-radius: 4px;
  --padding-bottom: 8px;
  --padding-end: 8px;
  --padding-start: 8px;
  --padding-top: 8px;
}

h2 {
  margin-top: 32px;
}

h5 {
  margin-top: 24px;
}
</style>
